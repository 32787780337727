// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import { setVersionComparisonExhibition } from '../../stores/VersionComparison';
import { debounce } from '../../utils/debounce';

class VersionComparisonModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showing: this.props.showing,
      scrollPosition: -1,
    };

    window.store.VersionComparison.watch(storeState => {
      const { showVersionComparisonModal } = storeState;

      this.hidePageScroll(showVersionComparisonModal);

      this.setState({
        showing: showVersionComparisonModal,
      });
    });

    this.watchScroll = this.watchScroll.bind(this);
    this.handleWheelScroll = this.handleWheelScroll.bind(this);
  }

  handleWheelScroll(event) {
    if (!this.modalNode) return;

    const isMobile = window.matchMedia('(max-width: 768px)').matches;
    if (isMobile) return;

    const scrolledClass = 'scrolled';
    const hasScrolledClass = this.modalNode.classList.contains(scrolledClass);

    const scrollIsOnTop = this.modalNode.scrollTop <= 1;
    const scrollIsFarFromTop = this.modalNode.scrollTop >= 50;

    const wheelScrolledToTop = event.deltaY < 0;
    const wheelScrolledToBottom = event.deltaY > 0;

    if (scrollIsFarFromTop && wheelScrolledToBottom && !hasScrolledClass) {
      this.modalNode.classList.add(scrolledClass);
    }

    if (scrollIsOnTop && wheelScrolledToTop && hasScrolledClass) {
      this.modalNode.classList.remove(scrolledClass);
    }
  }

  watchScroll() {
    if (!this.modalNode) return;

    this.setState({ scrollPosition: 0 });

    document.addEventListener('wheel', debounce(this.handleWheelScroll, 100));
  }

  hidePageScroll(showVersionComparisonModal) {
    if (
      showVersionComparisonModal &&
      document.body.style.overflow !== 'hidden'
    ) {
      document.body.style.overflow = 'hidden';
    }

    if (
      !showVersionComparisonModal &&
      document.body.style.overflow === 'hidden'
    ) {
      document.body.style.overflow = 'initial';
    }
  }

  render() {
    const { showing, scrollPosition } = this.state;

    if (!showing) return null;

    return (
      <div
        className="version-comparison--modal"
        ref={node => {
          this.modalNode = node;
          if (scrollPosition < 0) this.watchScroll();
        }}
      >
        <div className="version-comparison--modal-close-wrapper">
          <div className="container">
            <button
              className="btn button button--dark version-comparison--modal-close"
              type="button"
              onClick={() => setVersionComparisonExhibition(false)}
            >
              Fechar
            </button>
          </div>
        </div>
        <div className="version-comparison--modal-content">
          {this.props.children}
        </div>
      </div>
    );
  }
}
VersionComparisonModal.defaultProps = {
  showing: false,
  children: null,
};

VersionComparisonModal.propTypes = {
  showing: PropTypes.bool,
  children: PropTypes.node,
};

export default VersionComparisonModal;
