// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import { getInitialAccordionSlotsState } from '../../utils/versionComparison/getInitialAccordionSlotsState';
import { getVersionsTabsData } from '../../utils/versionComparison/getVersionsTabsData';
import ButtonSeeMore from './Accordion/ButtonSeeMore';
import SlotColumn from './Accordion/SlotColumn';
import ButtonInterest from './Accordion/ButtonInterest';

class AccordionComparisonContent extends Component {
  constructor(props) {
    super(props);

    this.tabs = [
      {
        tab_slug: 'detail_list',
        label: 'Especificações',
      },
      {
        tab_slug: 'comfort_list',
        label: 'Conforto',
      },
      {
        tab_slug: 'item_list',
        label: 'Segurança',
      },
      {
        tab_slug: 'connectivity_list',
        label: 'Conectividade',
      },
    ];

    this.state = {
      activeTab: null,
      expandedTab: null,
      tabIsExpansible: false,
      slots: getInitialAccordionSlotsState(),
      usableSlotsAmount: 3,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleExpandTab = this.handleExpandTab.bind(this);
    this.updateHeights = this.updateHeights.bind(this);

    this.slotRefs = [];
  }

  componentDidMount() {
    window.store.VersionComparison.watch(storeState => {
      const slots = getVersionsTabsData(storeState.slots);

      this.setState({
        slots,
        usableSlotsAmount: storeState.usableSlotsAmount,
      });
    });
  }

  componentDidUpdate() {
    this.updateHeights();
  }

  handleClick(tabId, index = 0) {
    const { activeTab } = this.state;
    const newActiveTab = tabId !== activeTab ? tabId : null;
    this.setState({
      activeTab: newActiveTab,
      expandedTab: null,
      tabIsExpansible: false,
    });

    const modal = document.querySelector('.version-comparison--modal');

    const accordionItemTitleHeightInPixels = 88;
    const constantScrollGap = 20;
    const topScrollGap =
      constantScrollGap + accordionItemTitleHeightInPixels * index;

    modal.scrollTo(0, topScrollGap);
  }

  handleExpandTab(tab_slug) {
    const currentExpandedTab = this.state.expandedTab;

    this.setState({
      expandedTab: currentExpandedTab === tab_slug ? null : tab_slug,
    });
  }

  updateHeights() {
    const heights =
      this.slotRefs &&
      this.slotRefs.map(ref => ref.getBoundingClientRect().height);

    const maxHeight = 600;
    const hasElementHigherThanMaxHeight = heights.some(
      height => height >= maxHeight,
    );

    if (hasElementHigherThanMaxHeight && !this.state.tabIsExpansible) {
      this.setState({ tabIsExpansible: true });
    }
  }

  render() {
    const {
      activeTab,
      expandedTab,
      tabIsExpansible,
      slots,
      usableSlotsAmount,
    } = this.state;

    return (
      <div className="version-comparison--accordion accordion-comparison">
        <div className="container">
          <ul className="accordion-comparison__items">
            {this.tabs.map(({ tab_slug, label }, index) => (
              <li
                key={tab_slug}
                className="accordion-comparison__item"
                data-active={tab_slug === activeTab}
              >
                <h3
                  className="accordion-comparison__item-title"
                  onClick={() => this.handleClick(tab_slug, index)}
                  onKeyUp={() => this.handleClick(tab_slug, index)}
                  role="presentation"
                >
                  {label}
                  <span className="expand-icon" />
                </h3>
                <div className="accordion-comparison__item-content">
                  <div className="accordion-comparison__item-content-wrapper">
                    <div
                      className={`accordion-comparison__slots slots--expansible version-comparison--columns-${usableSlotsAmount}`}
                      data-expanded={expandedTab === tab_slug}
                      data-is-expansible={tabIsExpansible}
                      ref={node => {
                        this.slotRefs[index] = node;
                      }}
                    >
                      {slots.slice(0, usableSlotsAmount).map(slot => (
                        <SlotColumn
                          hasVersion={slot.version}
                          items={slot.versionTabsContent[tab_slug].items}
                          hasTitle={slot.versionTabsContent[tab_slug].hasTitle}
                        />
                      ))}
                    </div>

                    <ButtonSeeMore
                      handleClick={() => this.handleExpandTab(tab_slug)}
                      expandedTab={expandedTab}
                      tabIsExpansible={tabIsExpansible}
                    />

                    <ul
                      className={`accordion-comparison__interest-ctas version-comparison--columns-${usableSlotsAmount}`}
                    >
                      {slots.map(slot => {
                        if (slot.version === null) return <span />;

                        return (
                          <ButtonInterest
                            version={slot.version}
                            active={
                              slot.versionTabsContent[tab_slug].items.length > 0
                            }
                          />
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

AccordionComparisonContent.defaultProps = {};

AccordionComparisonContent.propTypes = {};

export default AccordionComparisonContent;
