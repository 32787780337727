import { Component } from 'preact';
import PropTypes from 'prop-types';
import Scrollbars from '../../../Scrollbars';
import {
  removeFilter,
  setList,
  setSort,
} from '../../../../stores/DDMOffersFilter';
import { setURLSearchParams } from '../../../../utils/filters';

class DropdownFilter extends Component {
  constructor(props) {
    super(props);

    this.handleClearFilters = this.handleClearFilters.bind(this);
  }

  handleClearFilters() {
    removeFilter('keyword');
    removeFilter('model_category[]');
    setSort('');
    setList().then(() => {
      setURLSearchParams({}, [], {});
    });

    this.props.onClose();
  }

  render() {
    return (
      <div className="dropdown-filter search-filter-simple__drop search-filter-simple__item search-filter-simple__drop--active">
        <div className="card-collapse-deep search-filter-simple__drop-item">
          <div className="card-collapse-deep__header">
            <div>Filtrar por:</div>
            {this.props.showCloseButton ? (
              <button type="button" onClick={this.props.onClose}>
                <i className="icon icon-close text-dark" />
              </button>
            ) : null}
          </div>

          <ul className="list list--border-bottom">
            <li>
              <strong className="dropdown-filter__list-title">Categoria</strong>
              <Scrollbars autoHeight>
                <ul className="list list--no-style">
                  {this.props.filterCategories.map(category => (
                    <li>
                      <div className="custom-check custom-check--checkbox justify-content-start">
                        <label htmlFor={`category--${category}`}>
                          <input
                            type="checkbox"
                            value={category}
                            checked={this.props.selectedCategories.includes(
                              category,
                            )}
                            onChange={() =>
                              this.props.onChangeCategories(category)
                            }
                            id={`category--${category}`}
                          />
                          <div className="custom-check__check" />
                          {category}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </Scrollbars>
            </li>
          </ul>

          {this.props.showClearFiltersButton && (
            <ul className="list list--border-bottom pt-0 d-md-none">
              <button
                type="button"
                className="result-search-simple__clear-filters-button"
                onClick={this.handleClearFilters}
              >
                Limpar filtros
              </button>
            </ul>
          )}
        </div>
      </div>
    );
  }
}

DropdownFilter.defaultProps = {
  filterCategories: [],
  onChangeCategories() {},
  showCloseButton: false,
  onClose() {},
  showClearFiltersButton: false,
};

DropdownFilter.propTypes = {
  selectedCategories: PropTypes.arrayOf.isRequired,
  filterCategories: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChangeCategories: PropTypes.func,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  showClearFiltersButton: PropTypes.bool,
};

export default DropdownFilter;
