export const renderFeaturesDisplay = features => {
  if (!features) return;

  const featuresDisplayWrapper = document.querySelector(
    '.showcase-offer__features-display-wrapper',
  );

  if (!featuresDisplayWrapper) return;

  window.render(
    window.h(window.FeaturesDisplay, {
      features,
    }),
    featuresDisplayWrapper,
  );
};
