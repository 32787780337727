import { defaulVersionTabsContent } from './getInitialAccordionSlotsState';

export const getVersionsTabsData = slots => {
  const versionsTabs = slots.map(({ position, version }) => {
    if (!version) {
      return {
        position,
        version,
        versionTabsContent: defaulVersionTabsContent,
      };
    }

    const parsedDetailsList = version.detail_list.map(detail => {
      const [title, description] = detail.split(': ');
      return {
        title,
        description,
      };
    });

    return {
      position,
      version,
      versionTabsContent: {
        detail_list: {
          items: parsedDetailsList,
          hasTitle: true,
        },
        comfort_list: {
          items: version.comfort_list,
          hasTitle: false,
        },
        item_list: {
          items: version.item_list,
          hasTitle: false,
        },
        connectivity_list: {
          items: version.connectivity_list,
          hasTitle: false,
        },
      },
    };
  });

  return versionsTabs;
};
