import { createStore, createEvent } from 'effector';

export const setVersionComparisonExhibition = createEvent(
  'set version comparison exhibition',
);
export const setVersionSelectorExhibition = createEvent(
  'set version selector exhibition',
);
export const setConversionFormModalExhibition = createEvent(
  'set conversion form modal exhibition',
);
export const setCurrentSlotInSelect = createEvent('set current slot in select');
export const setUsableSlotsAmount = createEvent('set usable slots amount');
export const setSlots = createEvent('set slots');

const VersionComparison = (() => {
  let instance;

  return (
    defaultValue = {
      showVersionComparisonModal: false,
      showVersionSelectorModal: false,
      showConversionFormModal: false,
      currentSlotInSelect: 1,
      usableSlotsAmount: 3,
      slots: [],
      _storeEvents: {
        setVersionComparisonExhibition,
      },
    },
  ) => {
    if (!instance) {
      instance = createStore(defaultValue);

      instance.on(
        setVersionComparisonExhibition,
        (state, showVersionComparisonModal) => ({
          ...state,
          showVersionComparisonModal,
        }),
      );

      instance.on(
        setVersionSelectorExhibition,
        (state, showVersionSelectorModal) => ({
          ...state,
          showVersionSelectorModal,
        }),
      );

      instance.on(
        setConversionFormModalExhibition,
        (state, showConversionFormModal) => ({
          ...state,
          showConversionFormModal,
        }),
      );

      instance.on(setCurrentSlotInSelect, (state, currentSlotInSelect) => ({
        ...state,
        currentSlotInSelect,
      }));

      instance.on(setUsableSlotsAmount, (state, usableSlotsAmount) => ({
        ...state,
        usableSlotsAmount,
      }));

      instance.on(setSlots, (state, slots) => ({
        ...state,
        slots,
      }));
    }

    return instance;
  };
})();

export default VersionComparison;
