// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import { setVersionSelectorExhibition } from '../../stores/VersionComparison';
import GlassModal from './GlassModal';

class VersionSelectModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      versionsSelected: [],
      currentVersionSelected: null,
      currentSlotInSelect: 1,
    };

    this.handleSelectVersion = this.handleSelectVersion.bind(this);
    this.handleSetVersion = this.handleSetVersion.bind(this);
  }

  componentDidMount() {
    window.store.VersionComparison.watch(state => {
      const versionsSelected = state.slots.map(item =>
        item.version ? item.version.slug : null,
      );

      this.setState({
        versionsSelected,
        currentSlotInSelect: state.currentSlotInSelect,
      });
    });
  }

  handleSelectVersion(versionSlug) {
    const { versionsSelected, currentVersionSelected } = this.state;

    if (versionsSelected.includes(versionSlug)) return;

    const selectedVersion =
      currentVersionSelected === versionSlug ? null : versionSlug;

    this.setState({ currentVersionSelected: selectedVersion });
  }

  handleSetVersion() {
    const { currentSlotInSelect, currentVersionSelected } = this.state;
    const { handleSetVersion } = this.props;

    handleSetVersion(currentSlotInSelect, currentVersionSelected);
    setVersionSelectorExhibition(false);
    this.setState({
      currentVersionSelected: null,
    });
  }

  getVersionTitle(version) {
    return version.subtitle || version.name;
  }

  render() {
    const { versions, vehicle } = this.props;
    const { versionsSelected, currentVersionSelected } = this.state;

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <GlassModal
        name="VersionSelector"
        setModalExhibition={setVersionSelectorExhibition}
      >
        <div className="version-select-modal__content-header">
          <h4 className="version-select-modal__title">
            Compare as versões do {vehicle.name}
          </h4>
          <h5 className="version-select-modal__subtitle">
            Selecione uma versão para adicioná-la a comparação.
          </h5>
        </div>

        <div className="version-select-modal__content-body">
          <ul className="version-select-modal__options">
            {versions.map(version => (
              <li key={version.id} className="version-select-modal__option">
                <div
                  className="version-select-modal__card"
                  selected={versionsSelected.includes(version.slug)}
                  data-current-selected={
                    currentVersionSelected === version.slug
                  }
                  onClick={() => this.handleSelectVersion(version.slug)}
                  onKeyUp={() => this.handleSelectVersion(version.slug)}
                  role="button"
                  tabIndex={0}
                >
                  <input
                    className="version-select-modal__card-radio-input"
                    type="radio"
                    checked={
                      versionsSelected.includes(version.slug) ||
                      currentVersionSelected === version.slug
                    }
                  />
                  <div className="version-select-modal__card-content">
                    <p className="version-select-modal__card-title">
                      {this.getVersionTitle(version)}
                    </p>
                    <p className="version-select-modal__card-price">
                      {version.price && `A partir de R$ ${version.price}`}
                    </p>
                  </div>
                  <img
                    className="version-select-modal__card-thumb"
                    src={version.item_image.middle_image}
                    alt={this.getVersionTitle(version)}
                    width={120}
                    height={80}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="version-select-modal__content-footer">
          <button
            type="button"
            className="btn button button--primary version-select-modal__add-button"
            onClick={this.handleSetVersion}
            disabled={!currentVersionSelected}
          >
            Adicionar
          </button>
        </div>
      </GlassModal>
    );
  }
}
VersionSelectModal.defaultProps = {
  versions: [],
  vehicle: {},
  handleSetVersion: () => {},
};

VersionSelectModal.propTypes = {
  versions: PropTypes.arrayOf,
  vehicle: PropTypes.shape({
    name: PropTypes.string,
  }),
  handleSetVersion: PropTypes.func,
};

export default VersionSelectModal;
