import { Component } from 'react';
import PropTypes from 'prop-types';
import IMask from 'imask';
import CustomSelect from '#/CustomSelect';
import CustomCheck from '#/CustomCheck';
import SubmitButton from '#/SubmitButton';
import FormMessageOverlay from './FormMessageOverlay';
import handleInputChange from '%/utils/handleInputChange';
import validationFactory from '%/utils/validation';
import { phoneMasks, phoneMasksPt } from '%/utils/masks';
import getUTM from '%/utils/utm';
import InputCpf from './InputCpf'
import LocationFields from './Inputs/Location';
import { getChannelCountry } from "../utils/internacionalization";
import { setRecaptchaLoadOnFormFocus } from '../utils/reCAPTCHA';

class SimpleConversionForm extends Component {
  constructor(props) {
    super(props);

    this.resetState();

    this.clearForm = this.clearForm.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getFormData = this.getFormData.bind(this);
    this.handleContactOptionsChange = this.handleContactOptionsChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.versionInputChange = this.versionInputChange.bind(this);
    this.versionValid = this.versionValid.bind(this);
  }

  resetState() {
    this.setState({
      name: '',
      email: '',
      phone: '',
      unit: '',
      phoning: false,
      mailing: false,
      whatsapping: false,
      contactOptions: '',
      dataPermissions: false,
      cpf: '',
      model_car: '',
      version: '',
      versionValidStatus: true,
      dataPermissions: false,
      uf: null,
      city: null
    });
  }

  /**
   * Validação extra para o cpf
   */
  cpfValid() {
    return !this.props.showCpf || (this.props.showCpf && this.state.cpf)
  }

  handleStateChange(e) {
    const uf = e.target.value;
    this.setState({ uf })
  }

  handleCityChange(e) {
    const city = e.target.value;
    this.setState({ city });
  }

  versionValid() {
    return this.state.version;
  }

  getActiveVersion() {
    if (!window.store || !window.store.vehicleVersionDetails) return
    return window.store.vehicleVersionDetails.getState().activeVersion;
  }

  versionInputChange(e) {
    this.setState({ versionValidStatus: true });
    this.handleInputChange(e)
  }

  handleSubmit(e) {
    e.preventDefault();
    const invalidInputs = this.validator.validateAll(this.form);
    const formIsValid = invalidInputs.length === 0;

    if (this.props.versions && this.props.versions.length > 0) {
      if (!this.versionValid()) {
        this.setState({ versionValidStatus: false });
        return
      }
    }

    if (!formIsValid) {
      return;
    }

    if (!this.cpfValid()) {
      alert('O campo de CPF é obrigatório')
      return
    }

    // Se o form for válido, chamamos a prop para gerenciar a submissão do form.
    this.props.handleSubmit(e, this.getFormData()).then(() => {
      this.resetState();
    });
  }

  /**
   * Retorna os dados pertinentes para a conversão.
   * @return {*}
   */
  getFormData() {
    let state = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      model_car: this.state.model_car,
      mailing: this.state.mailing,
      phoning: this.state.phoning,
      whatsapping: this.state.whatsapping,
    };

    if (this.props.showCpf) {
      state.cpf = this.state.cpf
    }
    if (this.props.shouldShowDataPermissions) {
      state.data_permissions = this.state.dataPermissions
    }

    if (this.props.showLocationFields) {
      state.uf = this.state.uf
      state.city = this.state.city
    }

    let props = {
      product: this.props.product,
      bait: this.props.bait,
      channel: this.props.channel,
      category: this.props.category,
      brand: this.props.brand,
      utmz: getUTM()
    };

    const activeVersion = this.props.versions && this.props.versions.length > 0 && this.getActiveVersion();

    if (this.props.cloneId) {
      props['product_id'] = this.props.cloneId
    } else if (activeVersion && activeVersion.clone_id) {
      props['product_id'] = activeVersion.clone_id;
    }

    if (this.versionValid()) {
      props['version'] = this.state.version
    }

    if (!this.props.whatsAppResponder)
      props['whatsapp_no_responder'] = true

    if (this.props.unit) {
      props['unit'] = this.props.unit;
    } else if (this.props.units && this.props.units.length === 1) {
      props['unit'] = this.props.units[0].value;
    } else {
      props['unit'] = this.state.unit;
    }

    let plan = document.getElementsByName('plan')[0];
    let period = document.getElementsByName('period')[0];
    if (plan || period) {
      props["plan"] = plan.value;
      props["period"] = period.value
    }

    /*
    Para o consórcio, basta enviar o slug da versão que não necessariamente o dealer possui como oferta. Para isto
    usamos o campo 'model_car' que no integrador DCJ Grow (antigo Salesforce) é usado exatamente para este fim.
    */
    if (this.props.category === 'Consortium') {

      const { plans } = window.store.Consortium.getState();

      if (plans.version_slug) {
        props['model_car'] = plans.version_slug
      }
    }

    this.props.customFields.map(field => props[field.name] = field.value)

    return { ...state, ...props };
  }

  handleContactOptionsChange(e) {
    this.handleInputChange(e)
    this.state.contactOptions = this.contactOptionsValue() ? 'on' : ''
  }

  /*
   * Verifica se não há opções de contato selecionadas
   */
  contactOptionsValue() {
    return !!(this.state.phoning || this.state.mailing || this.state.whatsapping)
  }

  componentDidMount() {
    let masks = {
      'BR': phoneMasks,
      'PT': phoneMasksPt,
    };

    const phoneMask = IMask(this.phoneInput, {
      mask: masks[this.props.country || getChannelCountry()]
    });
    phoneMask.on("accept", () => this.setState({ phone: phoneMask.value }));

    this.validator = validationFactory(`#${this.props.formId}`);
    
    setRecaptchaLoadOnFormFocus();
  }

  componentDidUpdate() {
    window.whatsappDidUpdate();
  }

  clearForm(e) {
    e.preventDefault();
    this.resetState();
  }

  render() {
    return (
      <div>
        <form
          id={this.props.formId}
          className="form-modal form-modal--conversion"
          onSubmit={this.handleSubmit}
          ref={form => (this.form = form)}
        >
          <FormMessageOverlay
            handleClose={this.props.handleCloseOverlay}
            isVisible={this.props.showOverlay}
            type={this.props.errorSendingForm ? 'error' : 'success'}
          />
          <header className="form-modal__header">{this.props.titleForm}</header>
          <div className="form-modal__body">
            <header>
              {this.props.mainPhrase}
            </header>
            <fieldset>
              <div className="form-group">
                <input
                  type="text"
                  maxLength={80}
                  placeholder="Nome:"
                  name="name"
                  className="form-control"
                  onChange={this.handleInputChange}
                  value={this.state.name}
                  required
                  data-bouncer-target="#invalid-name"
                />
                <div id="invalid-name" className="invalid-feedback" />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  maxLength={80}
                  placeholder="E-mail:"
                  name="email"
                  className="form-control"
                  onChange={this.handleInputChange}
                  value={this.state.email}
                  required
                  data-bouncer-target="#invalid-email"
                />
                <div id="invalid-email" className="invalid-feedback" />
              </div>
              <div className="form-group">
                <input
                  type="phone"
                  placeholder="Telefone/WhatsApp:"
                  name="phone"
                  className="form-control"
                  ref={phoneInput => (this.phoneInput = phoneInput)}
                  value={this.state.phone}
                  required
                  onChange={this.handleInputChange}
                  data-bouncer-target="#invalid-phone"
                />
                <div id="invalid-phone" className="invalid-feedback" />
              </div>

              {
                this.props.showCpf &&
                <InputCpf
                  handleChange={this.handleInputChange}
                  value={this.state.cpf}
                />
              }

              {
                this.props.showLocationFields &&
                <LocationFields
                  handleStateChange={this.handleStateChange}
                  uf={this.state.uf}
                  handleCityChange={this.handleCityChange}
                  city={this.state.city}
                />
              }

              {this.props.versions && this.props.versions.length > 0 && (
                <div className="form-group">
                  <CustomSelect
                    handleSelectChange={this.versionInputChange}
                    name="version"
                    options={this.props.versions}
                    value={this.state.version}
                    shouldSort={false}
                    placeholderValue="Escolha um modelo"
                    searchEnabled={true}
                  />

                  {!this.state.versionValidStatus && (
                    <div id="invalid-version" className="invalid-feedback is-invalid-version" style="display: block;">
                      <div className="error-message" id="bouncer-error_version">Por favor, selecione esse campo</div>
                    </div>
                  )}
                </div>
              )}

              {!this.props.unit &&
                this.props.units &&
                this.props.units.length > 1 &&
                this.props.showUnits &&
                <div>
                  <legend class="control-label">Escolha a unidade:</legend>
                  <div className="form-group">
                    <CustomSelect
                      handleSelectChange={this.handleInputChange}
                      name="unit"
                      options={this.props.units}
                      value={this.state.unit}
                      shouldSort={false}
                      placeholderValue="Selecione uma unidade"
                      searchEnabled={false}
                    />
                  </div>
                </div>
              }

              <label className="control-label">Quero receber contato por:</label>
              <div className="form-check form-check-inline">
                <CustomCheck
                  checkStyle="checkbox-whatsapp"
                  name="mailing"
                  value="true"
                  type="checkbox"
                  isChecked={this.state.mailing}
                  onChangeCheckable={this.handleContactOptionsChange}
                >
                  E-mail
                </CustomCheck>
              </div>
              <div className="form-check form-check-inline">
                <CustomCheck
                  checkStyle="checkbox-whatsapp"
                  name="whatsapping"
                  value="true"
                  type="checkbox"
                  isChecked={this.state.whatsapping}
                  onChangeCheckable={this.handleContactOptionsChange}
                >
                  Whatsapp
                </CustomCheck>
              </div>
              <div className="form-check form-check-inline">
                <CustomCheck
                  checkStyle="checkbox-whatsapp"
                  name="phoning"
                  value="true"
                  type="checkbox"
                  isChecked={this.state.phoning}
                  onChangeCheckable={this.handleContactOptionsChange}
                >
                  Telefone
                </CustomCheck>
              </div>
              <div className="form-group contact-options">
                <input
                  type="text"
                  className="form-control d-none"
                  name="contact-options"
                  required
                  placeholder="Opções de contato"
                  data-bouncer-target="#invalid-contact-options"
                  value={this.state.contactOptions}
                  checked={this.state.contactOptions}
                />
                <div id="invalid-contact-options" className="invalid-feedback" />
              </div>
            </fieldset>
            {this.props.shouldShowDataPermissions && (
              <fieldset className='mt-0'>
                <div className="form-check form-check-inline conversion-form__data-permissions-field dark">
                  <CustomCheck
                    name="dataPermissions"
                    value="true"
                    isChecked={this.state.dataPermissions}
                    onChangeCheckable={this.handleInputChange}
                    type="checkbox"
                    checkStyle="checkbox-whatsapp"
                  >
                    {this.props.dataPermissionsCustomText}
                  </CustomCheck>
                </div>
              </fieldset>
            )}
            <fieldset className="consent-text mt-2">
              Ao informar meus dados, eu concordo com a{' '}
              <a href={this.props.linkPrivacyPolicy}>Política de privacidade</a>.
            </fieldset>
          </div>
          <footer>
            <a className="clear-fields" href="#" onClick={this.clearForm}>
              Limpar campos
            </a>
            <SubmitButton
              classes="btn form-modal__submit submit-button button button--primary mb-0"
              handleClick={this.handleSubmit}
              label="Converse agora!"
              isSubmitting={this.props.isSubmittingForm}
            />
          </footer>
        </form>
      </div>
    );
  }
}

SimpleConversionForm.defaultProps = {
  titleForm: 'Fale pelo WhatsApp',
  mainPhrase: 'Preencha o formulário abaixo e converse agora com um consultor:',
  handleSubmit() { },
  handleCloseOverlay() { },
  handleContactOptionsChange: function () { },
  isSubmittingForm: false,
  shouldShowDataPermissions: false,
  units: [],
  product: '',
  channel: '',
  category: '',
  brand: '-',
  bait: '-6',
  whatsAppResponder: false,
  showUnits: true,
  showCpf: false,
  versions: [],
  customFields: [],
  showLocationFields: false
};

SimpleConversionForm.propTypes = {
  titleForm: PropTypes.string,
  mainPhrase: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCloseOverlay: PropTypes.func,
  handleContactOptionsChange: PropTypes.func,
  showOverlay: PropTypes.bool,
  errorSendingForm: PropTypes.bool,
  isSubmittingForm: PropTypes.bool,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
    })
  ),
  product: PropTypes.string,
  cloneId: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  showLocationFields: PropTypes.bool,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  customFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  shouldShowDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
};

export default SimpleConversionForm;
