export const defaulVersionTabsContent = {
  detail_list: {
    items: [],
    hasTitle: false,
  },
  comfort_list: {
    items: [],
    hasTitle: false,
  },
  item_list: {
    items: [],
    hasTitle: false,
  },
  connectivity_list: {
    items: [],
    hasTitle: false,
  },
};

export const getInitialAccordionSlotsState = () => [
  {
    position: 1,
    version: null,
    versionTabsContent: defaulVersionTabsContent,
  },
  {
    position: 2,
    version: null,
    versionTabsContent: defaulVersionTabsContent,
  },
  {
    position: 3,
    version: null,
    versionTabsContent: defaulVersionTabsContent,
  },
];
