import classNames from 'classnames';
import { Component } from 'preact';
import PropTypes from 'prop-types';

class DropdownOrder extends Component {
  render() {
    const { sortOptions, selectedSort, onClose } = this.props;

    return (
      <div className="dropdown-order search-filter-simple__drop search-filter-simple__item">
        <div className="card-collapse-deep search-filter-simple__drop-item">
          <div className="card-collapse-deep__header">
            <div>Ordenar por:</div>
            {this.props.showCloseButton ? (
              <button type="button" onClick={onClose}>
                <i className="icon icon-close text-dark" />
              </button>
            ) : null}
          </div>

          <ul className="list list--border-bottom">
            {sortOptions.map(sortItem => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
              <li
                key={sortItem.title}
                className={classNames(
                  'card-collapse-deep__title card-collapse-deep__title--link',
                  {
                    'card-collapse-deep__title--link-active':
                      selectedSort === sortItem.paramValue,
                  },
                )}
                onClick={() => this.props.handleChangeSort(sortItem)}
              >
                <strong>{sortItem.title}</strong>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

DropdownOrder.defaultProps = {
  sortOptions: [],
  selectedSort: null,
  handleChangeSort() {},
  showCloseButton: false,
  onClose() {},
};

DropdownOrder.propTypes = {
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      paramValue: PropTypes.string,
    }),
  ),
  selectedSort: PropTypes.string,
  handleChangeSort: PropTypes.func,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DropdownOrder;
