// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import { setConversionFormModalExhibition } from '../../../stores/VersionComparison';

class ButtonInterest extends Component {
  constructor(props) {
    super(props);

    this.handleOpenFormModal = this.handleOpenFormModal.bind(this);
  }

  handleOpenFormModal() {
    window.store.vehicleVersionDetails.setState({
      activeVersion: this.props.version,
    });
    setConversionFormModalExhibition(true);
  }

  render() {
    if (!this.props.active) return null;

    return (
      <button
        type="button"
        className="button btn button--ghost accordion-comparison__interest-cta"
        onClick={() => this.handleOpenFormModal()}
      >
        Tenho interesse
      </button>
    );
  }
}
ButtonInterest.defaultProps = {
  active: [],
  version: null,
};

ButtonInterest.propTypes = {
  active: PropTypes.bool,
  version: PropTypes.objectOf,
};

export default ButtonInterest;
