// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';

class GlassModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showing: this.props.showing,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    window.store.VersionComparison.watch(state => {
      this.setState({
        showing: state[`show${this.props.name}Modal`],
      });
    });
  }

  handleClickOutside(event) {
    if (this.selectModalRef && !this.selectModalRef.contains(event.target)) {
      this.props.setModalExhibition(false);
    }
  }

  render() {
    const { children, setModalExhibition } = this.props;
    if (!this.state.showing) return null;

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className="glass-modal"
        onClick={this.handleClickOutside}
        onKeyDown={this.handleClickOutside}
      >
        <div
          className="glass-modal--wrapper"
          ref={node => {
            this.selectModalRef = node;
          }}
        >
          <button
            className="btn button glass-modal__button-close"
            type="button"
            onClick={() => setModalExhibition(false)}
            title="Fechar modal do formulário"
          >
            <i className="icon icon-close" />
          </button>

          <div className="glass-modal__content">{children}</div>
        </div>
      </div>
    );
  }
}
GlassModal.defaultProps = {
  showing: false,
  children: null,
};

GlassModal.propTypes = {
  showing: PropTypes.bool,
  setModalExhibition: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default GlassModal;
