import { Component } from 'preact';
import PropTypes from 'prop-types';

class SearchTerm extends Component {
  constructor(props) {
    super(props);

    this.handleClickSearch = this.handleClickSearch.bind(this);
  }

  handleClickSearch(event) {
    event.preventDefault();

    this.props.handleClickSearch();
  }

  render() {
    return (
      <div className="search-term">
        <button
          type="submit"
          className="search-term__btn"
          onClick={this.handleClickSearch}
          aria-label="Pesquisar"
        >
          <i className="icon icon-search-b" />
        </button>

        <div className="search-term__wrapper">
          <input
            className="search-term__input"
            type="text"
            value={this.props.value}
            onInput={e => this.props.handleChangeKeyword(e.target.value)}
            placeholder={this.props.placeholder}
          />
        </div>
      </div>
    );
  }
}

SearchTerm.defaultProps = {
  value: '',
  placeholder: 'O que está buscando?',
  handleChangeKeyword() {},
  handleClickSearch() {},
};

SearchTerm.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  handleChangeKeyword: PropTypes.func,
  handleClickSearch: PropTypes.bool,
};

export default SearchTerm;
