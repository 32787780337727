// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import VersionSelectCard from './VersionSelectCard';
import VersionSelectEmptyCard from './VersionSelectEmptyCard';
import {
  setCurrentSlotInSelect,
  setSlots,
  setUsableSlotsAmount,
  setVersionSelectorExhibition,
} from '../../stores/VersionComparison';
import { getInitialSlotState } from '../../utils/versionComparison/getInitialSlotState';

class VersionSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slots: [],
      usableSlotsAmount: 3,
    };

    this.handleUnsetVersion = this.handleUnsetVersion.bind(this);
    this.updateUsableSlotsAmount = this.updateUsableSlotsAmount.bind(this);
  }

  componentDidMount() {
    window.store.VersionComparison.watch(storeState => {
      this.setState({
        slots: storeState.slots,
        usableSlotsAmount: storeState.usableSlotsAmount,
      });
    });

    const initialSlotsState = getInitialSlotState(this.props.versions);
    setSlots(initialSlotsState);

    this.updateUsableSlotsAmount();
    window.addEventListener('resize', this.updateUsableSlotsAmount);
  }

  handleUnsetVersion(position) {
    const updatedSlots = this.state.slots.map(item => {
      if (item.position === position) {
        return { ...item, version: null };
      }

      return item;
    });

    const lastFilledSlotIndex = updatedSlots.reduce(
      (lastFilledIndex, slot, currentIndex) =>
        slot.version ? currentIndex : lastFilledIndex,
      0,
    );
    const lastSlotIsEmpty = !updatedSlots[updatedSlots.length - 1].version;

    if (lastSlotIsEmpty && updatedSlots[lastFilledSlotIndex + 2])
      updatedSlots[lastFilledSlotIndex + 2].disabled = true;

    setSlots(updatedSlots);
  }

  updateUsableSlotsAmount() {
    const { slots } = this.state;
    const slotsProvidedAMount = slots.length;
    const mobileMaxSlotsAmount = 2;
    const desktopMaxSlotsAmount = 3;

    const isMobile = window.matchMedia('(max-width: 768px)').matches;
    const usableSlotsAmount = isMobile
      ? mobileMaxSlotsAmount
      : Math.min(slotsProvidedAMount, desktopMaxSlotsAmount);

    this.setState({
      usableSlotsAmount,
    });
    setUsableSlotsAmount(usableSlotsAmount);
  }

  render() {
    const { slots, usableSlotsAmount } = this.state;
    const { vehicleName, versions } = this.props;

    return (
      <div className="version-comparison--select-wrapper">
        <div className="version-comparison--select version-select">
          <div className="version-select__header">
            <h1 className="version-comparison__title">
              Compare as versões do {vehicleName}
            </h1>

            <p className="version-select__header-title">
              Selecione até {usableSlotsAmount} versões para comparação:
            </p>
          </div>
          <ul
            className={`version-comparison--select__versions version-comparison--columns-${usableSlotsAmount}`}
          >
            {slots.slice(0, usableSlotsAmount).map(item => (
              <li key={item.position} className="version-select__slot">
                {item.version ? (
                  <VersionSelectCard
                    version={item.version}
                    exchangeable={versions.length > 2}
                    handleClick={() => {
                      setVersionSelectorExhibition(true);
                      setCurrentSlotInSelect(item.position);
                    }}
                    handleUnsetVersion={() =>
                      this.handleUnsetVersion(item.position)
                    }
                  />
                ) : (
                  <VersionSelectEmptyCard
                    disabled={item.disabled}
                    handleClick={() => {
                      setVersionSelectorExhibition(true);
                      setCurrentSlotInSelect(item.position);
                    }}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
VersionSelect.defaultProps = {
  versions: [],
  vehicleName: '',
};

VersionSelect.propTypes = {
  versions: PropTypes.arrayOf,
  vehicleName: PropTypes.string,
};

export default VersionSelect;
