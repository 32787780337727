import { h, Component } from 'preact';
import PropTypes from 'prop-types';

import SimpleConversionForm from './SimpleConversionForm';
import { isSafari } from '%/utils/isSafari';

class SimpleConversionFormApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmittingForm: false,
      errorSendingForm: false,
      showOverlay: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseOverlay = this.handleCloseOverlay.bind(this);
  }

  handleCloseOverlay() {
    this.setState({ showOverlay: false });
  }

  openWhatsAppLink() {
    const { category } = this.props;
    const { errorSendingForm } = this.state;

    var link = this.props.link;

    // se encontrar o combobox verifica a necessidade de alterar o link
    if ($('.choices__item.choices__item--selectable')[0] != undefined) {
      var selected_unit = $('.choices__item.choices__item--selectable')[0].dataset.value;
      if (this.props.phones) {
        this.props.phones.forEach(function (current) {
          if (current.area == category && current.unit == selected_unit) {
            link = current.link;
          }
        });
      }
    }

    if (link && !errorSendingForm) {
      isSafari() ? location.href = link : window.open(link, '_blank');
    }
  }

  handleSubmit(e, params) {
    this.setState({ isSubmittingForm: true });

    return serviceConversion
      .convert(params)
      .then(() => {
        this.setState({
          isSubmittingForm: false,
          errorSendingForm: false
        });
      })
      .catch(error => {
        this.setState({
          isSubmittingForm: false,
          errorSendingForm: true
        });
        console.error(error);
      })
      .then(() => {
        this.setState({
          showOverlay: true
        });
      });
  }

  render() {
    const {
      formId,
      units,
      unit,
      product,
      channel,
      category,
      whatsAppResponder
    } = this.props;

    return (
      <SimpleConversionForm
        formId={formId}
        handleSubmit={this.handleSubmit}
        isSubmittingForm={this.state.isSubmittingForm}
        errorSendingForm={this.state.errorSendingForm}
        handleCloseOverlay={this.handleCloseOverlay}
        showOverlay={this.state.showOverlay}
        linkPrivacyPolicy={this.props.linkPrivacyPolicy}
        units={units}
        unit={unit}
        product={product}
        channel={channel}
        category={category}
        brand={this.props.brand}
        country={this.props.country}
        bait={this.props.bait}
        whatsAppResponder={whatsAppResponder}
        showUnits={this.props.showUnits}
        showCpf={this.props.showCpf}
        cloneId={this.props.cloneId}
        versions={this.props.versions}
        customFields={this.props.customFields}
        showLocationFields={this.props.showLocationFields}
        contactsWithDynamicRequired={this.props.contactsWithDynamicRequired}
        shouldShowDataPermissions={this.props.shouldShowDataPermissions}
        dataPermissionsCustomText={this.props.dataPermissionsCustomText}
        titleForm={this.props.titleForm}
        mainPhrase={this.props.mainPhrase}
      />
    );
  }
}

SimpleConversionFormApp.defaultProps = {
  units: [],
  brand: '-',
  showUnits: true,
  showCpf: false,
  versions: [],
  showLocationFields: false,
  contactsWithDynamicRequired: false,
  shouldShowDataPermissions: false,
};

SimpleConversionFormApp.propTypes = {
  formId: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  unit: PropTypes.unit,
  product: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  country: PropTypes.string,
  bait: PropTypes.string,
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  showLocationFields: PropTypes.bool,
  phones: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      unit: PropTypes.string,
      area: PropTypes.string,
    })
  ),
  cloneId: PropTypes.string,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  customFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  contactsWithDynamicRequired: PropTypes.bool,
  shouldShowDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
};

export default SimpleConversionFormApp;
