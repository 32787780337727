// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';

class VersionSelectCard extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (!this.unsetButtonRef) return;

    this.props.handleClick();
  }

  getVersionTitle() {
    const { version } = this.props;

    return version.subtitle || version.name;
  }

  render() {
    const { version, exchangeable, handleUnsetVersion } = this.props;

    return (
      <div
        className="version-comparison--select-card version-select-card"
        onClick={this.handleClick}
        onKeyUp={this.handleClick}
        role="button"
        tabIndex={0}
      >
        {exchangeable && (
          <button
            className="version-select-card__delete"
            type="button"
            onClick={handleUnsetVersion}
            ref={node => {
              this.unsetButtonRef = node;
            }}
          >
            <i className="icon icon-close" />
          </button>
        )}
        <img
          className="version-select-card__thumb"
          src={version.item_image.middle_image}
          alt={this.getVersionTitle()}
          width={255}
          height={175}
        />
        <h3 className="version-select-card__title">{this.getVersionTitle()}</h3>
        <h3 className="version-select-card__price">
          {version.price && `A partir de R$ ${version.price}`}
        </h3>
      </div>
    );
  }
}
VersionSelectCard.defaultProps = {
  version: {},
  exchangeable: true,
  handleClick: () => {},
  handleUnsetVersion: () => {},
};

VersionSelectCard.propTypes = {
  version: PropTypes.objectOf,
  exchangeable: PropTypes.bool,
  handleClick: PropTypes.func,
  handleUnsetVersion: PropTypes.func,
};

export default VersionSelectCard;
