// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import {
  defaultHighlights,
  getVersionsHighlightsData,
} from '../../utils/versionComparison/getVersionsHighlightData';

class HighlighedComparisonContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slots: [
        {
          position: 1,
          versionHighlights: defaultHighlights,
        },
        {
          position: 2,
          versionHighlights: defaultHighlights,
        },
        {
          position: 3,
          versionHighlights: defaultHighlights,
        },
      ],
      usableSlotsAmount: 3,
      headerIsCollapsed: false,
    };

    this.handleToggleCollapseHeader =
      this.handleToggleCollapseHeader.bind(this);
  }

  componentDidMount() {
    window.store.VersionComparison.watch(storeState => {
      const slots = getVersionsHighlightsData(storeState.slots);

      this.setState({
        slots,
        usableSlotsAmount: storeState.usableSlotsAmount,
      });
    });
  }

  handleToggleCollapseHeader(event) {
    const target = event.target;
    const modalNode = target.closest('.version-comparison--modal');
    if (!modalNode) return;

    const scrolledClass = 'scrolled';
    const hasScrolledClass = modalNode.classList.contains(scrolledClass);

    if (hasScrolledClass) {
      modalNode.classList.remove(scrolledClass);
      this.setState({ headerIsCollapsed: false });
    } else {
      modalNode.classList.add(scrolledClass);
      this.setState({ headerIsCollapsed: true });
    }
  }

  render() {
    const { slots, usableSlotsAmount, headerIsCollapsed } = this.state;

    return (
      <div className="version-comparison--highlighted-content">
        <div className="container">
          <div className="highlighted-content__wrapper">
            <ul className="highlighted-content__headers highlighted-content__slot">
              <li className="highlighted-content__header-item">
                <i className="icon icon-motor" />
                Motor
              </li>
              <li className="highlighted-content__header-item">
                <i className="icon icon-transmission" />
                Câmbio
              </li>
              <li className="highlighted-content__header-item">
                <i className="icon icon-fuel" />
                Combustível
              </li>
            </ul>
            <div
              className={`highlighted-content__slots-content version-comparison--columns-${usableSlotsAmount}`}
            >
              {slots.slice(0, usableSlotsAmount).map(slot => (
                <ul
                  key={slot.position}
                  className="highlighted-content__slot-version highlighted-content__slot"
                >
                  <li
                    className="highlighted-content__slot-line"
                    data-placeholder={!slot.versionHighlights.motor}
                  >
                    <span className="highlighted-content__slot-line-title highlighted-content__header-item">
                      <i className="icon icon-motor" />
                      Motor
                    </span>
                    <p className="highlighted-content__slot-line-description">
                      {slot.versionHighlights.motor}
                    </p>
                  </li>
                  <li
                    className="highlighted-content__slot-line"
                    data-placeholder={!slot.versionHighlights.transmission}
                  >
                    <span className="highlighted-content__slot-line-title highlighted-content__header-item">
                      <i className="icon icon-transmission" />
                      Câmbio
                    </span>
                    <p className="highlighted-content__slot-line-description">
                      {slot.versionHighlights.transmission}
                    </p>
                  </li>
                  <li
                    className="highlighted-content__slot-line"
                    data-placeholder={!slot.versionHighlights.fuel}
                  >
                    <span className="highlighted-content__slot-line-title highlighted-content__header-item">
                      <i className="icon icon-fuel" />
                      Combustível
                    </span>
                    <p className="highlighted-content__slot-line-description">
                      {slot.versionHighlights.fuel}
                    </p>
                  </li>
                </ul>
              ))}
            </div>
          </div>
          <div
            className="accordion-comparison--arrow-scroll"
            onClick={this.handleToggleCollapseHeader}
            onKeyUp={this.handleToggleCollapseHeader}
            role="button"
            tabIndex={0}
            data-header-is-collapsed={headerIsCollapsed}
          >
            <span className="accordion-comparison--arrow-scroll-text scroll-text--desktop">
              Exibir mais
            </span>
            <span className="accordion-comparison--arrow-scroll-text scroll-text--mobile">
              {headerIsCollapsed ? 'Exibir mais' : 'Exibir menos'}
            </span>
            <i className="icon icon-arrow-d" />
          </div>
        </div>
      </div>
    );
  }
}

HighlighedComparisonContent.defaultProps = {};
HighlighedComparisonContent.propTypes = {};

export default HighlighedComparisonContent;
