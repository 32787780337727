/* eslint-disable func-names */
export function debounce(func, delay) {
  let timeoutId;

  return function () {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;

    clearTimeout(timeoutId);

    // eslint-disable-next-line prefer-arrow-callback
    timeoutId = setTimeout(function () {
      func.apply(context, args);
    }, delay);
  };
}
