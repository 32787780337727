import { Component } from 'preact';
import PropTypes from 'prop-types';
import {
  setPerPage,
  setList,
  nextPage,
  incrementList,
  setMethodApi,
} from '../../../../../stores/SearchFilter';
import CardCitroen from '../../../../Cards/NewOffersCitroen';
import CardPeugeot from '../../../../Cards/Psa';
import PlaceholderCard from '../../../../Cards/Placeholder';

class ListNewOffersApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingMore: false,
      list: [],
    };

    const { perPage } = this.props;

    setMethodApi('getOffers');
    setPerPage(perPage);
    setList();

    window.store.SearchFilter.watch((state) => {
      this.setState({
        list: state.list,
        loading: state.loading,
        loadingMore: state.loadingMore,
        page: state.page,
        total_pages: state.total_pages,
        shouldLoadMore: state.page < state.total_pages,
        showList: !state.showConversionForm,
      });

      this.formatList();
    });
  }

  formatList() {
    const { urlPrefix } = this.props;
    const formattedVehicles = this.state.list.map((vehicle) => {
      return {
        title: vehicle.name,
        image: vehicle.image || vehicle.item_image.middle_image,
        link: urlPrefix + '/' + vehicle.slug,
        price: vehicle.price,
        offer: vehicle.item_offer,
        sealList: vehicle.seal_list,
      };
    });

    this.setState({
      formattedList: formattedVehicles,
    });
  }

  handleLoadMore(e) {
    e.preventDefault();
    nextPage();
    incrementList();
  }

  shouldShowList() {
    return this.state.list.length > 0;
  }

  componentDidUpdate() {
    window.matchHeight.match('.new-offers-listing__list');
  }

  componentDidMount() {
    window.matchHeight.match('.new-offers-listing__list');
  }

  fakeList() {
    const totalVehicles = Number(this.props.totalVehicles);
    const fakeArray = Array(totalVehicles).fill();
    return fakeArray;
  }

  render() {
    const {
      formattedList,
      shouldLoadMore,
      loadingMore,
      loading,
      showList,
    } = this.state;

    const { isPeugeot } = this.props;

    if (showList) {
      return (
        <div class="new-offers-listing__content">
          <div class="new-offers-listing__list">
            {formattedList.length > 0
              ? formattedList.map((vehicle) => {
                return isPeugeot ? (
                  <CardPeugeot
                    class="new-vehicles-listing__item"
                    title={vehicle.title}
                    brand="Peugeot"
                    image={vehicle.image}
                    link={vehicle.link}
                    price={vehicle.price}
                    offer={vehicle.offer}
                    sealList={vehicle.sealList}
                    lazy={true}
                  ></CardPeugeot>
                ) : (
                  <CardCitroen
                    class="new-offers-listing__item"
                    title={vehicle.title}
                    image={vehicle.image}
                    link={vehicle.link}
                    price={vehicle.price}
                    offer={vehicle.offer}
                    sealList={vehicle.sealList}
                    lazy={true}
                  ></CardCitroen>
                );
              })
              : this.fakeList().map((_) => <PlaceholderCard />)}
          </div>

          {(shouldLoadMore || loadingMore) && (
            <div class="new-offers-listing__load-more-items">
              <a
                href="#"
                class="btn button button--ghost"
                onClick={this.handleLoadMore}
              >
                {loadingMore ? (
                  <div class="new-offers__loading">
                    <div className="mr-2 spinner-border small" role="status">
                      <span className="sr-only">Carregando...</span>
                    </div>
                    &nbsp; Carregando...
                  </div>
                ) : (
                  'Carregar mais ofertas'
                )}
              </a>
            </div>
          )}
        </div>
      );
    }
  }
}

ListNewOffersApp.defaultProps = {
  perPage: 12,
  list: [],
  formattedList: [],
  totalVehicles: 8,
  urlPrefix: '',
  isPeugeot: false,
};
ListNewOffersApp.propTypes = {
  perPage: PropTypes.number,
  totalVehicles: PropTypes.number,
  urlPrefix: PropTypes.string,
  isPeugeot: PropTypes.boolean,
};

export default ListNewOffersApp;
