// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import { loadScriptReCAPTCHA } from '%/utils/reCAPTCHA';
import { setConversionFormModalExhibition } from '../../stores/VersionComparison';
import GlassModal from './GlassModal';
import NewVehicleConversionFormApp from '../NewVehicleConversionFormApp';

class ConversionFormModal extends Component {
  componentDidMount() {
    loadScriptReCAPTCHA();
  }

  render() {
    const { conversionFormProps } = this.props;

    return (
      <div className="version-comparison__conversion-form">
        <GlassModal
          name="ConversionForm"
          setModalExhibition={setConversionFormModalExhibition}
        >
          {conversionFormProps && (
            <NewVehicleConversionFormApp
              titleForm={
                conversionFormProps.titleForm ||
                'PREENCHA O FORMULÁRIO ABAIXO  E AGUARDE O CONTATO DE UM DE NOSSOS CONSULTORES.'
              }
              mainPhrase={
                conversionFormProps.mainPhrase ||
                'Preencha o formulário abaixo e aguarde o contato de um de nossos consultores.'
              }
              buttonLabel={
                conversionFormProps.buttonLabel || 'ESTOU INTERESSADO'
              }
              showLabels
              shouldShowVariantSelect={false}
              shouldShowImage={false}
              shouldShowPrice={false}
              showVehicleExchange={false}
              formId={conversionFormProps.formId}
              units={conversionFormProps.units}
              product={conversionFormProps.product}
              cloneId={conversionFormProps.cloneId}
              channel={conversionFormProps.channel}
              category={conversionFormProps.category}
              brand={conversionFormProps.brand}
              showUnits={conversionFormProps.showUnits}
              showCpf={conversionFormProps.showCpf}
              showLocationFields={conversionFormProps.showLocationFields}
              contactsWithDynamicRequired={
                conversionFormProps.contactsWithDynamicRequired
              }
              shouldShowDataPermissions={
                conversionFormProps.shouldShowDataPermissions
              }
              dataPermissionsCustomText={
                conversionFormProps.dataPermissionsCustomText
              }
              linkPrivacyPolicy={conversionFormProps.linkPrivacyPolicy}
              privacyPolicyStyle={conversionFormProps.privacyPolicyStyle}
            />
          )}
        </GlassModal>
      </div>
    );
  }
}
ConversionFormModal.defaultProps = {};

ConversionFormModal.propTypes = {
  conversionFormProps: PropTypes.objectOf({
    titleForm: PropTypes.string,
    mainPhrase: PropTypes.string,
    buttonLabel: PropTypes.string,
    units: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    product: PropTypes.string.isRequired,
    cloneId: PropTypes.string.isRequired,
    channel: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    brand: PropTypes.string,
    showUnits: PropTypes.bool,
    showCpf: PropTypes.bool,
    shouldShowDataPermissions: PropTypes.bool,
    dataPermissionsCustomText: PropTypes.string,
    linkPrivacyPolicy: PropTypes.string.isRequired,
  }).isRequired,
};

export default ConversionFormModal;
