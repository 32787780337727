const handleToggleDropdown = (event, dropdown, activeClass) => {
  if (!event.target.classList.contains('nav-dropdown__link'))
    event.preventDefault();

  dropdown.classList.toggle(activeClass);
};

const handleCloseOnClickOutside = (event, dropdown, activeClass) => {
  const isClickOutside = !dropdown.contains(event.target);
  if (isClickOutside) dropdown.classList.remove(activeClass);
};

const setDropdownInteration = ({
  dropdownWrapperSelector,
  toggleButtonSelector,
  activeClass = 'active',
  closeOnClickOutside = true,
}) => {
  const itemsWithDropdown = Array.from(
    document.querySelectorAll(dropdownWrapperSelector)
  );
  if (!itemsWithDropdown) return;

  itemsWithDropdown.forEach((dropdown) => {
    const toggleButton = dropdown.querySelector(toggleButtonSelector);
    if (!toggleButton) return;

    toggleButton.addEventListener('click', (event) => {
      handleToggleDropdown(event, dropdown, activeClass);
    });

    if (closeOnClickOutside) {
      document.addEventListener('click', (event) => {
        handleCloseOnClickOutside(event, dropdown, activeClass);
      });
    }
  });
};

export { setDropdownInteration };
