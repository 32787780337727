// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import VersionComparisonModal from './Modal';
import VersionSelect from './VersionSelect';
import HighlighedComparisonContent from './HighlightedComparisonContent';
import AccordionComparisonContent from './AccordionComparisonContent';
import VersionSelectModal from './VersionSelectModal';
import { setSlots } from '../../stores/VersionComparison';
import ConversionFormModal from './ConversionFormModal';

class VersionComparison extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slots: [],
    };

    this.handleSetVersion = this.handleSetVersion.bind(this);
  }

  componentDidMount() {
    window.store.VersionComparison.watch(state => {
      this.setState({
        slots: state.slots,
      });
    });
  }

  handleSetVersion(position, slug) {
    const updatedSlots = this.state.slots.map(item => {
      if (item.position === position) {
        const selectedVersion = this.props.versions.find(
          version => version.slug === slug,
        );

        return { ...item, version: selectedVersion };
      }

      return item;
    });

    const firstLockedSlotPosition =
      updatedSlots.findIndex(slot => slot.disabled) + 1;

    const hasLockedSlot = firstLockedSlotPosition > 0;
    const lastPositionIsFilled = position === firstLockedSlotPosition - 1;

    const shouldUnlockSlot = hasLockedSlot && lastPositionIsFilled;

    if (shouldUnlockSlot)
      updatedSlots[firstLockedSlotPosition - 1].disabled = false;

    setSlots(updatedSlots);
  }

  render() {
    const { versions, vehicle, conversionFormProps } = this.props;

    if (versions.length < 2) return null;

    return (
      <VersionComparisonModal>
        <div className="version-comparison">
          <div className="version-comparison__header">
            <div className="container">
              <VersionSelect versions={versions} vehicleName={vehicle.name} />
            </div>
          </div>
          <VersionSelectModal
            versions={versions}
            vehicle={vehicle}
            handleSetVersion={this.handleSetVersion}
            conversionFormProps={conversionFormProps}
          />
          <HighlighedComparisonContent />
          <AccordionComparisonContent />
          <ConversionFormModal conversionFormProps={conversionFormProps} />
        </div>
      </VersionComparisonModal>
    );
  }
}
VersionComparison.defaultProps = {
  versions: [],
  vehicle: {},
};

VersionComparison.propTypes = {
  versions: PropTypes.arrayOf,
  vehicle: PropTypes.shape({
    name: PropTypes.string,
  }),
  conversionFormProps: PropTypes.objectOf({
    units: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    product: PropTypes.string.isRequired,
    cloneId: PropTypes.string.isRequired,
    channel: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    brand: PropTypes.string,
    showUnits: PropTypes.bool,
    showCpf: PropTypes.bool,
    shouldShowDataPermissions: PropTypes.bool,
    dataPermissionsCustomText: PropTypes.string,
    linkPrivacyPolicy: PropTypes.string.isRequired,
    versions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
  }).isRequired,
};

export default VersionComparison;
