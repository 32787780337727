import { Component } from 'preact';
import PropTypes from 'prop-types';
import ButtonFilter from './ButtonFilter';
import DropdownFilter from './DropdownFilter';
import DropdownOrder from './DropdownOrder';
import ButtonOrder from './ButtonOrder';
import SearchTerm from './SearchTerm';
import {
  addFilter,
  setSort,
  setList,
  setPage,
  removeFilter,
  setApiVersion,
} from '../../../../stores/DDMOffersFilter';
import { isFilterArray } from '../../../../utils/filters';

class SimpleFilter extends Component {
  constructor(props) {
    super(props);

    setApiVersion('v2');

    this.state = {
      showDropdownFilter: false,
      showDropdownOrder: false,
      filterCategories: [],
      selectedCategories: [],
      selectedSort: null,
      keyword: null,
    };

    this.handleButtonFilterToggle = this.handleButtonFilterToggle.bind(this);
    this.handleChangeCategories = this.handleChangeCategories.bind(this);
    this.handleButtonOrderToggle = this.handleButtonOrderToggle.bind(this);
    this.handleChangeSort = this.handleChangeSort.bind(this);
    this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.addFilterParamsToUrl = this.addFilterParamsToUrl.bind(this);

    window.store.DDMOffers.watch(state => {
      if (state.data.length === 0) return;
      if (this.state.filterCategories.length > 0) return;

      const filterCategories = window.ddmOffersRepository.getAllCategories();

      this.setState({
        filterCategories,
      });
    });

    window.store.DDMOffersFilter.watch(state => {
      const storeSelectedCategories = state.filters['model_category[]'];
      const storeKeyword = state.filters.keyword;

      if (storeSelectedCategories !== this.state.selectedCategories)
        this.setState({
          selectedCategories: [],
        });

      if (storeKeyword !== this.state.keyword)
        this.setState({
          keyword: '',
        });
    });

    this.applyInitialFilters();
  }

  componentDidMount() {
    this.addEventListeners();
  }

  handleButtonFilterToggle() {
    this.setState(previousState => ({
      showDropdownFilter: !previousState.showDropdownFilter,
    }));
  }

  handleButtonOrderToggle() {
    this.setState(previousState => ({
      showDropdownOrder: !previousState.showDropdownOrder,
    }));
  }

  handleChangeCategories(category) {
    const { selectedCategories } = this.state;

    const newCategories = selectedCategories.includes(category)
      ? selectedCategories.filter(c => c !== category)
      : [...selectedCategories, category];

    this.setState({
      selectedCategories: newCategories,
    });

    if (newCategories.length > 0) {
      addFilter({
        'model_category[]': newCategories,
      });
    } else {
      removeFilter('model_category[]');
    }

    this.updateList();
  }

  handleChangeSort(sort) {
    this.setState({
      selectedSort: sort.paramValue,
      showDropdownOrder: false,
    });

    setSort(sort.paramValue);
    this.updateList();
  }

  handleChangeKeyword(newKeyword) {
    this.setState({
      keyword: newKeyword,
    });
  }

  handleClickSearch() {
    addFilter({
      keyword: this.state.keyword,
    });
    this.updateList();
  }

  addFilterParamsToUrl() {
    const { filters, sort } = window.store.DDMOffersFilter.getState();

    const paramsToConvert = {
      ...filters,
      sort,
    };

    const urlParams = Object.entries(paramsToConvert).reduce(
      (previousParamString, [key, value]) => {
        if (!value) return previousParamString;

        const newParam = `&${key}=${value}`;
        return previousParamString + newParam;
      },
      '',
    );

    const url = new URL(window.location);
    const searchParams = new URLSearchParams(urlParams);

    url.search = searchParams.toString();
    window.history.pushState(null, null, url);
  }

  applyInitialFilters() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.size === 0) return;

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of urlSearchParams.entries()) {
      const currentValue = isFilterArray(key) ? value.split(',') : value;

      if (key === 'sort') {
        this.setState({
          selectedSort: currentValue,
        });

        setSort(currentValue);
      } else {
        if (key === 'keyword') {
          this.setState({
            keyword: currentValue,
          });
        }

        if (key === 'model_category[]') {
          this.setState({
            selectedCategories: currentValue,
          });
        }

        addFilter({ [key]: currentValue });
      }
    }

    setPage(1);
    setList();
  }

  updateList() {
    setPage(1);
    setList();
    this.addFilterParamsToUrl();
  }

  addEventListeners() {
    window.addEventListener('click', event => {
      if (
        this.refFilterCategory &&
        event.target !== this.refFilterCategory &&
        !this.refFilterCategory.contains(event.target)
      ) {
        if (this.state.showDropdownFilter) {
          this.setState({ showDropdownFilter: false });
        }
      }

      if (
        this.refOrder &&
        event.target !== this.refOrder &&
        !this.refOrder.contains(event.target)
      ) {
        if (this.state.showDropdownOrder) {
          this.setState({ showDropdownOrder: false });
        }
      }
    });
  }

  render() {
    const { showDropdownFilter, showDropdownOrder } = this.state;

    return (
      <form className="simple-filter" onSubmit={this.handleSubmitSearch}>
        <div className="row">
          <div className="simple-filter__buttons">
            <div
              ref={node => {
                this.refFilterCategory = node;
              }}
              className="simple-filter__label-filter"
            >
              <ButtonFilter
                onClick={this.handleButtonFilterToggle}
                isOpen={showDropdownFilter}
                title={this.props.filterButtonTitle}
                icon={this.props.filterButtonIcon}
              />

              {showDropdownFilter && (
                <DropdownFilter
                  selectedCategories={this.state.selectedCategories}
                  filterCategories={this.state.filterCategories}
                  onChangeCategories={this.handleChangeCategories}
                  showCloseButton={this.props.showDropdownCloseButtons}
                  onClose={this.handleButtonFilterToggle}
                  showClearFiltersButton={this.props.showClearFiltersButton}
                />
              )}
            </div>

            <div
              ref={node => {
                this.refOrder = node;
              }}
              className="simple-filter__label-order"
            >
              <ButtonOrder
                onClick={this.handleButtonOrderToggle}
                isOpen={showDropdownOrder}
                title={this.props.sortButtonTitle}
                icon={this.props.sortButtonIcon}
              />

              {showDropdownOrder && (
                <DropdownOrder
                  className="d-none d-md-block"
                  sortOptions={this.props.sortOptions}
                  handleChangeSort={this.handleChangeSort}
                  selectedSort={this.state.selectedSort}
                  showCloseButton={this.props.showDropdownCloseButtons}
                  onClose={this.handleButtonOrderToggle}
                />
              )}
            </div>
          </div>
          <div className="simple-filter__label-text">
            <SearchTerm
              className="simple-filter__item"
              value={this.state.keyword}
              handleChangeKeyword={this.handleChangeKeyword}
              handleClickSearch={this.handleClickSearch}
            />
          </div>
        </div>
      </form>
    );
  }
}

SimpleFilter.defaultProps = {
  sortOptions: [
    { title: 'Por Preço Maior/Menor', paramValue: '-price_value' },
    { title: 'Por Preço Menor/Maior', paramValue: 'price_value' },
    { title: 'Por Nome A/Z', paramValue: 'name' },
    { title: 'Por Nome Z/A', paramValue: '-name' },
  ],
  filterButtonTitle: 'Filtrar',
  sortButtonTitle: 'Ordenar',
  filterButtonIcon: 'icon-chevron',
  sortButtonIcon: 'icon-chevron',
  showDropdownCloseButtons: false,
  showClearFiltersButton: false,
};

SimpleFilter.propTypes = {
  sortOptions: PropTypes.arrayOf,
  filterButtonTitle: PropTypes.string,
  sortButtonTitle: PropTypes.string,
  filterButtonIcon: PropTypes.string,
  sortButtonIcon: PropTypes.string,
  showDropdownCloseButtons: PropTypes.bool,
  showClearFiltersButton: PropTypes.bool,
};

export default SimpleFilter;
