import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class UsedVehiclesCitroenCard extends Component {
  formattedPrice() {
    return `R$ ${this.props.price}`; // TODO
  }

  technicalItems() {
    const { km, exchange, fuelText } = this.props;
    const items = [
      {
        label: 'Câmbio',
        value: exchange,
      },
      {
        label: 'Quilometragem',
        value: km && `${km} KM`,
      },
      {
        label: 'Combustível',
        value: fuelText,
      },
    ];

    return items;
  }

  render() {
    const { title, image, link, value, lazy, brand } = this.props;

    return (
      <div className="used-vehicle-card">
        <a className="used-vehicle-card__link" href={link}>
          <img
            className={classNames('used-vehicle-card__image', {
              'swiper-lazy': lazy,
            })}
            src={image}
            alt={title}
            width="200"
            height="145"
          />
        </a>
        <div className="used-vehicle-card__content">
          <h3 className="used-vehicle-card__title" data-match-height="used-vehicle-card__title" >{title}</h3>
          <h4 className="used-vehicle-card__subtitle">{brand}</h4>
          <ul
            className="used-vehicle-card__highlights"
            data-match-height="used-vehicle-card__highlights"
          >
            {this.technicalItems().map(
              (item) =>
                !!item.value && (
                  <li className="used-vehicle-card__highlight">
                    <i class="icon icon-chevron"></i>
                    <strong>{item.label}:</strong>
                    {item.value}
                  </li>
                )
            )}
          </ul>
          <div class="used-vehicle-card__price">
            <p className="used-vehicle-card__price-title">Por apenas</p>
            <p className="used-vehicle-card__price-value">
              {this.formattedPrice()}
            </p>
          </div>
          <a className="btn button button--large button--primary" href={link}>
            Tenho interesse
          </a>
        </div>
      </div>
    );
  }
}

UsedVehiclesCitroenCard.defaultProps = {
  title: '',
  image: '',
  link: '',
  exchange: '',
  km: '',
  fuelText: '',
  price: '',
  lazy: false,
  brand: 'Citroen'
};

UsedVehiclesCitroenCard.propTpes = {
  title: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  exchange: PropTypes.string,
  km: PropTypes.string,
  fuelText: PropTypes.string,
  price: PropTypes.string,
  lazy: PropTypes.boolean,
  brand: PropTypes.string,
};
