import { Component } from 'preact';
import PropTypes from 'prop-types';
import { nextPage, incrementList } from '../../../stores/DDMOffersFilter';

class ButtonLoadMore extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { onClick } = this.props;

    if (onClick) {
      this.props.onClick();
    }

    nextPage();
    incrementList();
  }

  render() {
    const { loadingMore } = this.props;

    let contentButton;

    if (loadingMore) {
      contentButton = (
        <div>
          <div className="ml-2 spinner-border" role="status">
            <span className="sr-only">Carregando...</span>
          </div>
          &nbsp; Carregando...
        </div>
      );
    } else {
      contentButton = 'Ver mais ofertas';
    }

    return (
      <div className="container text-center">
        <button
          className="btn button button--large button--dark button--block"
          type="button"
          onClick={this.handleClick}
        >
          {contentButton}
        </button>
      </div>
    );
  }
}

ButtonLoadMore.defaultProps = {
  loadingMore: false,
  onClick: () => {},
};

ButtonLoadMore.propTypes = {
  loadingMore: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ButtonLoadMore;
