import { Component } from 'preact';
import PropTypes from 'prop-types';
import {
  removeFilter,
  setList,
  setSort,
} from '../../../../../stores/DDMOffersFilter';
import { setURLSearchParams } from '../../../../../utils/filters';

class ResultSearchSimple extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showResults: false,
      showConversionForm: false,
    };

    this.handleClearFilters = this.handleClearFilters.bind(this);

    this.watch();
  }

  // eslint-disable-next-line react/sort-comp
  watch() {
    window.store.DDMOffersFilter.watch(state => {
      this.setState({
        showResults: !!state.filters.keyword,
        keyword: state.filters.keyword,
        total: state.list.length,
        showConversionForm: state.list.length === 0 && state.loading === false,
      });
    });
  }

  handleClearFilters() {
    removeFilter('keyword');
    removeFilter('model_category[]');
    setSort('');
    setList().then(() => {
      setURLSearchParams({}, [], {});
    });
  }

  render() {
    const { showResults, showConversionForm, keyword, total } = this.state;
    const { showQuotes, showClearFiltersButton, showResultsAndFormTogether } =
      this.props;

    // not displaying results when conversionForm is displayed and showResultsAndFormTogether is false
    if (showConversionForm && !showResultsAndFormTogether) return null;
    if (!showResults) return null;

    return (
      <div className="result-search-simple">
        {keyword && (
          <div className="result-search-simple__container">
            {showQuotes ? (
              <div className="result-search-simple__keyword-container">
                Buscando por{' '}
                <span className="result-search-simple__keyword">
                  &quot;{keyword}&quot;
                </span>
              </div>
            ) : (
              <div className="result-search-simple__keyword-container">
                Buscando por:{' '}
                <span className="result-search-simple__keyword">{keyword}</span>
              </div>
            )}
            {showClearFiltersButton && (
              <div>
                <button
                  type="button"
                  onClick={this.handleClearFilters}
                  className="result-search-simple__clear-filters-button"
                >
                  Limpar filtros
                </button>
              </div>
            )}
          </div>
        )}
        <div>
          <span className="result-search-simple__total">{total}</span>{' '}
          {total > 1 ? 'resultados encontrados' : 'resultado encontrado'}
        </div>
      </div>
    );
  }
}

ResultSearchSimple.propTypes = {
  showQuotes: PropTypes.bool,
  showClearFiltersButton: PropTypes.bool,
  showResultsAndFormTogether: PropTypes.bool,
};

ResultSearchSimple.defaultProps = {
  showQuotes: true,
  showClearFiltersButton: false,
  showResultsAndFormTogether: false,
};

export default ResultSearchSimple;
