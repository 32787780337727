export const defaultHighlights = {
  motor: null,
  transmission: null,
  fuel: null,
};

export const getVersionsHighlightsData = slots => {
  const versionsHighlights = slots.map(({ position, version }) => {
    if (!version) {
      return {
        position,
        versionHighlights: defaultHighlights,
      };
    }

    const parsedDetailsList = version.detail_list.reduce((list, detailItem) => {
      const [title, description] = detailItem.split(': ');
      const formattedTitle = title
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      return Object.assign(list, { [formattedTitle]: description });
    }, {});

    const versionHighlights = {
      motor: parsedDetailsList.motor,
      transmission: parsedDetailsList.transmissao,
      fuel: parsedDetailsList.combustivel,
    };

    return {
      position,
      versionHighlights,
    };
  });

  return versionsHighlights;
};
