// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';

class SlotColumn extends Component {
  render() {
    const { items, hasTitle, hasVersion } = this.props;

    return (
      <ul className="accordion-comparison__slot">
        {!hasVersion && items.length === 0 && (
          <span className="accordion-comparison__empty-slot-placeholder">
            Selecione uma versão
          </span>
        )}

        {hasVersion && items.length === 0 && (
          <span className="accordion-comparison__empty-slot-placeholder">
            Sem informações disponíveis
          </span>
        )}

        {hasTitle
          ? items.map(item => (
              <li className="accordion-comparison__slot-item">
                <p>
                  <b>{item.title}</b>: {item.description}
                </p>
              </li>
            ))
          : items.map(item => (
              <li className="accordion-comparison__slot-item">{item}</li>
            ))}
      </ul>
    );
  }
}
SlotColumn.defaultProps = {
  items: [],
  hasTitle: false,
  hasVersion: false,
};

SlotColumn.propTypes = {
  items: PropTypes.arrayOf(),
  hasTitle: PropTypes.bool,
  hasVersion: PropTypes.bool,
};

export default SlotColumn;
