import { Component } from 'preact';
import PropTypes from 'prop-types';
import CustomSelect from '../CustomSelect';
import VariantCollection, {
  variantObjectsToCustomSelectOptions,
} from '../../utils/variantCollection';
import handleInputChange from '../../utils/handleInputChange';
import { Select } from '../Forms/FormComponents';
import { registerField } from '%/utils/customForm';
import { addField, updateFieldValue } from '../../stores/CustomFormFields';

/**
 * @property {VariantCollection} variantsCollection
 */
export default class ShowcaseChoiceVersion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedVariant: null,
      variants: this.props.variants,
      offer: {},
    };

    this.getCurrentVariantItem = this.getCurrentVariantItem.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.handleVariantChange = this.handleVariantChange.bind(this);

    this.setOffer();
    this.watchStore();
  }

  componentWillMount() {
    // eslint-disable-next-line no-unused-expressions
    this.props.cloneId &&
      window.service.getNewModelDetails(this.props.cloneId).then(res => {
        this.setState({
          variants: res.entries,
        });
      });
  }

  componentWillUpdate() {
    this.variantsCollection = new VariantCollection(this.state.variants);

    if (this.state.selectedVariant === null) {
      const selectedVariant = this.variantsCollection.findVariantBy(
        'slug',
        this.props.defaultVariant,
      );

      if (selectedVariant) {
        // eslint-disable-next-line react/no-will-update-set-state
        this.setState({
          selectedVariant,
        });
      }
    }
  }

  componentDidUpdate() {
    this.props.isFromCustomForm &&
      addField({ key: 'version', value: this.getSelectedVariant() });

    const price = Array.from(
      document.querySelectorAll('.showcase-new-cars__price span strong'),
    )[0];
    if (!price) return;
    price.innerHTML = price.innerHTML.replace('R$', '<span>R$</span>');
  }

  handleVariantChange(e) {
    this.handleInputChange(e);

    const currentVariantItem = this.getCurrentVariantItem();
    if (!currentVariantItem) return;

    const { shouldShowVariantSelect, changeURLOnVersionChange } = this.props;
    const hasVariantsOptions = this.variantOptionsToCustomSelect().length > 0;

    if (shouldShowVariantSelect && hasVariantsOptions) {
      if (changeURLOnVersionChange) {
        this.historyPushState();
      }

      const { selectedVariant, variants } = this.state;

      const index = changeURLOnVersionChange
        ? this.getIndexByURLSlugVersion()
        : this.getIndexBySlugVersion(selectedVariant);

      window.store.vehicleVersionDetails.setState({
        activeVersion: variants[index],
      });

      if (this.props.isFromCustomForm) {
        updateFieldValue({ key: 'version', value: selectedVariant });
      }
    }

    this.props.handleVariantChange(currentVariantItem);
  }

  setOffer() {
    const { isOffer, offer: fixedOffer } = this.props;
    const currentVariantOffer = this.getCurrentVariantOffer();

    if (isOffer || !currentVariantOffer) {
      this.setState({ offer: fixedOffer });
    } else {
      this.setState({ offer: currentVariantOffer });
    }
  }

  /**
   * Retorna a url já com a versão selecionada para ser setada no history
   */
  getUrlVersion() {
    const href = window.location.pathname;
    const slugVersion = this.getSlugVersion();
    const currentVersion = this.getCurrentVariantItem();
    const newSlugVersion = currentVersion.getVariantProperty('slug');

    const newPathname = href
      .replace(/\/$/, '')
      .replace(slugVersion, '')
      .replace(/\/$/, '');

    return `${window.location.origin}${newPathname}/${newSlugVersion}${window.location.search}`;
  }

  /**
   * Retorna o slug da versão a parti da url
   */
  getSlugVersion() {
    const matches = window.location.pathname.match(/(novo|oferta).+$/);
    if (matches && matches.length > 0) {
      const pathItems = matches[0].split('/');
      if (pathItems.length > 2) {
        return pathItems[2].replace(/\//g, '');
      }
    }
    return '';
  }

  /**
   * Retorna a posicação no array das variants(versões) a partir do slug da versão retirada da url
   */
  getIndexByURLSlugVersion() {
    const slugVersion = this.getSlugVersion();
    const index = this.getIndexBySlugVersion(slugVersion);

    return index;
  }

  /**
   * Retorna a posicação no array das variants(versões) a partir do slug da versão
   */
  getIndexBySlugVersion(slugVersion) {
    const versionIndex = this.state.variants.findIndex(
      variant => variant.slug === slugVersion,
    );

    if (versionIndex !== undefined && versionIndex >= 0) {
      return versionIndex;
    }

    const defaultVariant = this.props.defaultVariant;
    const versionDefault = this.state.variants.findIndex(
      variant => variant.slug === defaultVariant,
    );

    return versionDefault > 0 ? versionDefault : 0;
  }

  /**
   * Obtém a foto da variante atualmente selecionada.
   * @return {string}
   */
  getCurrentVariantPhotoUrl() {
    return (
      this.getCurrentVariantItem() &&
      this.getCurrentVariantItem().profileImages.url
    );
  }

  /**
   * Obtém o item da variante atualmente escolhido
   * @return {VariantItem|null}
   */
  getCurrentVariantItem() {
    const { selectedVariant } = this.state;

    return (
      selectedVariant &&
      this.variantsCollection &&
      this.variantsCollection.findVariantBy('slug', selectedVariant)
    );
  }

  getCurrentVariantItemPrice() {
    const selectedVariantItem = this.getCurrentVariantItem();
    return (
      selectedVariantItem &&
      selectedVariantItem.getVariantProperty('price_value')
    );
  }

  getCurrentVariantName() {
    const selectedVariantItem = this.getCurrentVariantItem();
    return (
      selectedVariantItem && selectedVariantItem.getVariantProperty('name')
    );
  }

  getCurrentVariantTitle() {
    const selectedVariantItem = this.getCurrentVariantItem();
    return (
      selectedVariantItem && selectedVariantItem.getVariantProperty('title')
    );
  }

  getCurrentVariantOffer() {
    const selectedVariantItem = this.getCurrentVariantItem();
    return (
      selectedVariantItem &&
      selectedVariantItem.getVariantProperty('item_offer')
    );
  }

  getSelectedVariant() {
    if (this.state.selectedVariant) {
      return this.state.selectedVariant;
    }

    if (this.variantOptionsToCustomSelect().length > 0) {
      const index = this.getIndexByURLSlugVersion();

      window.store.vehicleVersionDetails.setState({
        activeVersion: this.state.variants[index],
      });
      return this.variantOptionsToCustomSelect()[index].value;
    }

    return '';
  }

  handleFeaturedPrice(featuredPrice) {
    const { showFeaturedPriceWithCurrencyHighlighted } = this.props;

    if (showFeaturedPriceWithCurrencyHighlighted) {
      const splitedFeaturedPrice = String(featuredPrice).split(' ');
      const currency = `${splitedFeaturedPrice[0]} `;
      const fullPrice = `${splitedFeaturedPrice[1]}`;

      return (
        <div>
          <span className="showcase-offer__promotional-price-currency-highlighted">
            {currency}
          </span>
          {fullPrice}
        </div>
      );
    }

    return featuredPrice;
  }

  /**
   * Indica se o preço deve ser exibido
   * @return {bool}
   */
  shouldShowPrice() {
    const { isOffer, shouldShowPrice } = this.props;

    if (!shouldShowPrice) return false;

    return isOffer || this.getCurrentVariantItemPrice();
  }

  /**
   * Converte a prop de variantes para o formato utilizado no custom select:
   * { value: "foo", name: "bar" }
   */
  variantOptionsToCustomSelect() {
    return variantObjectsToCustomSelectOptions(
      this.state.variants,
      'slug',
      'name',
    );
  }

  historyPushState() {
    if (window.history.pushState) {
      window.history.pushState({}, null, this.getUrlVersion());
    }
  }

  watchStore() {
    window.store.vehicleVersionDetails.watch(({ activeVersion }) => {
      if (activeVersion && this.getCurrentVariantItem()) {
        // eslint-disable-next-line no-unused-expressions
        activeVersion && this.setState({ selectedVariant: activeVersion.slug });
        // eslint-disable-next-line no-unused-expressions
        activeVersion && this.setOffer();
      }
    });
  }

  render() {
    const imageUrl = this.getCurrentVariantPhotoUrl();
    const { badge, showVariantLabel, showVehicleThumb } = this.props;
    const { expiredAt } = this.props;
    const { offer } = this.state;

    return (
      <div>
        <h1 className="showcase-new-cars__title">
          {this.getCurrentVariantTitle()}
          {badge && (
            <span className="showcase__badge badge badge--pill">{badge}</span>
          )}
        </h1>
        {showVariantLabel && (
          <h2 className="showcase-new-cars__variant-name">
            {this.getCurrentVariantName()}
          </h2>
        )}
        <div className="showcase-new-cars__variants">
          <CustomSelect
            classNames={{
              containerOuter: `choices choices--psa${
                showVariantLabel ? ' variant-select--hide' : ''
              }`,
            }}
            id="select-variants"
            name="selectedVariant"
            handleSelectChange={this.handleVariantChange}
            options={this.variantOptionsToCustomSelect()}
            value={this.getSelectedVariant()}
            placeholder={false}
            removeItemButton={false}
            searchEnabled={false}
          />
        </div>
        <div className="showcase-new-cars__thumb-wrapper">
          {this.shouldShowPrice() && !!offer && (
            <div className="showcase-new-cars__price">
              {expiredAt ? (
                <p key={"warning-text"} className="showcase-offer__expired-offer">Oferta expirada!</p>
              ): (
                <div key={"prices"}>
                  {offer.secondary_call && (
                    <p className="showcase-offer__starting-price">
                      {offer.secondary_call}
                    </p>
                  )}
                  {offer.main_call && (
                    <small className="showcase-offer__text-by">
                      {offer.main_call}
                    </small>
                  )}
                  {offer.featured && (
                    <div className="showcase-offer__promotional-price">
                      {this.handleFeaturedPrice(offer.featured)}
                    </div>
                  )}
                  {offer.description && (
                    <div className="showcase-offer__description">
                      {offer.description}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {showVehicleThumb && (
            <div className="showcase-new-cars__thumb">
              {imageUrl && (
                <img src={imageUrl} alt={this.getCurrentVariantTitle()} />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

// default props
ShowcaseChoiceVersion.defaultProps = {
  handleVariantChange: () => {},
  shouldShowPrice: true,
  shouldShowVariantSelect: true,
  defaultVariant: null,
  offer: {},
  showVariantLabel: false,
  isFromCustomForm: false,
  isOffer: false,
  badge: null,
  cloneId: null,
  variants: [],
  changeURLOnVersionChange: true,
  showFeaturedPriceWithCurrencyHighlighted: false,
  showVehicleThumb: true,
};

// props config
ShowcaseChoiceVersion.propTypes = {
  handleVariantChange: PropTypes.func,
  shouldShowPrice: PropTypes.bool,
  shouldShowVariantSelect: PropTypes.bool,
  defaultVariant: PropTypes.string,
  cloneId: PropTypes.number,
  badge: PropTypes.string,
  offer: {
    secondary_call: PropTypes.string,
    main_call: PropTypes.string,
    featured: PropTypes.string,
    description: PropTypes.string,
  },
  variants: PropTypes.arrayOf(),
  showVariantLabel: PropTypes.bool,
  isFromCustomForm: PropTypes.bool,
  isOffer: PropTypes.bool,
  changeURLOnVersionChange: PropTypes.bool,
  showFeaturedPriceWithCurrencyHighlighted: PropTypes.bool,
  showVehicleThumb: PropTypes.bool,
};
