// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';

class ButtonSeeMore extends Component {
  render() {
    const { tabIsExpansible, expandedTab, handleClick } = this.props;

    if (!tabIsExpansible) return null;

    return (
      <div className="accordion-comparison__see-more-wrapper">
        <button
          type="button"
          className="accordion-comparison__see-more"
          onClick={handleClick}
        >
          {expandedTab ? 'Fechar lista -' : 'Ver lista completa +'}
        </button>
      </div>
    );
  }
}
ButtonSeeMore.defaultProps = {
  tabIsExpansible: false,
  expandedTab: false,
  handleClick: () => {},
};

ButtonSeeMore.propTypes = {
  tabIsExpansible: PropTypes.bool,
  expandedTab: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default ButtonSeeMore;
