// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';

class VersionSelectEmptyCard extends Component {
  constructor(props) {
    super(props);

    this.handleOpenModal = this.handleOpenModal.bind(this);
  }

  handleOpenModal() {
    const { disabled, handleClick } = this.props;
    if (disabled) return;
    handleClick();
  }

  render() {
    const { disabled } = this.props;

    return (
      <div
        className="version-comparison--select-empty-card version-select-empty-card"
        disabled={disabled}
        onClick={this.handleOpenModal}
        onKeyUp={this.handleOpenModal}
        role="button"
        tabIndex={0}
      >
        <div className="version-select-empty-card__add">
          <div className="version-select-empty-card__add-button">
            <i className="icon icon-close" />
          </div>
        </div>
        <p className="version-select-empty-card__subtitle">
          Selecione uma versão
        </p>
      </div>
    );
  }
}
VersionSelectEmptyCard.defaultProps = {
  disabled: false,
  handleClick: () => {},
};

VersionSelectEmptyCard.propTypes = {
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default VersionSelectEmptyCard;
