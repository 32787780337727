const checkIfHasMoreThanTwoVersions = versions => versions.length > 2;

export const getInitialSlotState = versions => {
  const hasMoreThanTwoVersions = checkIfHasMoreThanTwoVersions(versions);
  const technicalSheetActiveVersion =
    window.store.vehicleVersionDetails &&
    window.store.vehicleVersionDetails.getState().activeVersion;
  const firstVersion = technicalSheetActiveVersion || versions[0];

  const secondVersion = hasMoreThanTwoVersions
    ? null
    : versions.find(version => version.slug !== firstVersion.slug) ||
      versions[1];

  const initialSlotsState = [
    {
      position: 1,
      version: firstVersion,
      disabled: false,
    },
    {
      position: 2,
      version: secondVersion,
      disabled: false,
    },
  ];

  if (hasMoreThanTwoVersions) {
    initialSlotsState.push({
      position: 3,
      version: null,
      disabled: true,
    });
  }

  return initialSlotsState;
};
